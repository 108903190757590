import * as React from 'react';
import { ChangeEvent, ReactNode } from 'react';
import { injectIntl } from 'react-intl';
import { Dialog } from '../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../UIKit/components/Dialog/Dialog.types';
import { FormGroup } from '../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import theme from './MatrixSettingsDialog.scss';
import messages from './MatrixSettingsDialog.messages';
import { Row, Tabs, Popover, Table, Button, Col, Form, Input, Menu, Dropdown, Upload } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IMatrixNode } from '../../models/bpm/bpm-model-impl.types';
import {
    CustomMatrixState,
    IconMatrixState,
    IconMatrixStateIconEnum,
    MatrixData,
    MatrixHeader,
    MatrixHeaderTypeEnum,
    MatrixState,
    MatrixStateTypeEnum,
    NodeMatrixHeader,
    SimpleMatrixHeader,
    UserIconMatrixState,
} from '../../serverapi/api';
import { MatrixHeaderEnum } from './matrixHeaderEnum';
import { matrixService } from '../../services/MatrixService';
import { Icon } from '../UIKit/components/Icon/Icon.component';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';
import icTextColor from '../../resources/icons/text-color.svg';
import iconOk from '../../resources/icons/Symbol_OK.svg';
import { ColorResult } from 'react-color';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    MinusOutlined,
    PlusOutlined,
    UpOutlined,
    InboxOutlined,
} from '@ant-design/icons';
import { PictureSymbolConstants } from '../../models/pictureSymbolConstants';
import { PushDirectionEnum } from './headerPushDirectionEnum';
import { TreeItemType } from '../Tree/models/tree';
import { TreeNode } from '../../models/tree.types';
import { TMatrixSettingsDialogProps } from './MatrixSettingsDialog.types';
import { SettingsAxis } from './SettingsAxis.component';
import { MatrixHeaderButtons } from './MatrixHeaderComponent';
import { DialogFooterButtons } from '../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TMatrixSettingsDialogState = {
    content: IMatrixNode;
    newColor: string;
    newHeader: string;
    newDescription: string;
    newSymbol: string;
    newFileIcon?: File;
};

class MatrixSettingsDialog extends React.Component<TMatrixSettingsDialogProps, TMatrixSettingsDialogState> {
    newSymbolType: MatrixStateTypeEnum;
    columns: Array<ColumnProps<MatrixState>>;

    newSymbolIconType: IconMatrixStateIconEnum;

    backupMatrix: IMatrixNode;
    constructor(props: TMatrixSettingsDialogProps) {
        super(props);
        this.newSymbolType = 'CUSTOM';
        this.newSymbolIconType = 'PLUS';
        this.backupMatrix = JSON.parse(JSON.stringify(props.content));
        this.state = {
            content: props.content,
            newColor: '#000000',
            newHeader: '',
            newDescription: '',
            newSymbol: '',
        };
        this.setContent = this.setContent.bind(this);
    }

    handleHeaderButtons(type: MatrixHeaderEnum, orderNumber: number, direction?: PushDirectionEnum) {
        if (direction === undefined) return this.deleteHeader(type, orderNumber);

        return this.pushHeader(type, orderNumber, direction);
    }

    onDrop(file: File) {
        this.setState({ newFileIcon: file });
    }

    onClickHandler(stateId: string, direction?: PushDirectionEnum) {
        if (direction === undefined) return this.deleteState(stateId);

        return this.pushState(stateId, direction);
    }

    setNewHeader(value: string) {
        this.setState({ newHeader: value });
    }

    setNewSymbol(value: string) {
        this.setState({ newSymbol: value });
    }

    setNewDescription(value: string) {
        this.setState({ newDescription: value });
    }

    setContent(data: MatrixData) {
        this.setState({
            content: {
                ...this.state.content,
                data: {
                    ...this.state.content.data!,
                    states: [...data.states],
                },
            },
        });
    }

    getHeadersChilds(type: MatrixHeaderEnum): ReactNode[] {
        const { content } = this.state;
        const node: IMatrixNode = content;
        const headers: MatrixHeader[] = type === MatrixHeaderEnum.ColumnHeader ? node.data!.columns : node.data!.rows;

        return headers.map((header) => (
            <MatrixHeaderButtons
                treeItemsById={this.props.treeItemsById}
                header={header}
                renderHeader={this.renderHeader}
                deleteHeader={() => this.handleHeaderButtons(type, header.orderNumber)}
                pushDownHeader={() => this.handleHeaderButtons(type, header.orderNumber, PushDirectionEnum.DOWN)}
                pushUpHeader={() => this.handleHeaderButtons(type, header.orderNumber, PushDirectionEnum.UP)}
            />
        ));
    }

    getStateTypeTitle(): string {
        const { intl } = this.props;
        if (this.newSymbolType === 'CUSTOM') {
            return intl.formatMessage(messages.customState);
        }
        if (this.newSymbolType === 'ICON') {
            return intl.formatMessage(messages.iconState);
        }
        if (this.newSymbolType === 'USER_ICON') {
            return intl.formatMessage(messages.userIconState);
        }
        throw new Error('No such type matrix state');
    }

    getStateIconTitle(): string {
        const { intl } = this.props;
        if (this.newSymbolIconType === 'PLUS') {
            return intl.formatMessage(messages.plusIcon);
        }
        if (this.newSymbolIconType === 'MINUS') {
            return intl.formatMessage(messages.minusIcon);
        }
        if (this.newSymbolIconType === 'CROSS') {
            return intl.formatMessage(messages.crossIcon);
        }
        if (this.newSymbolIconType === 'CHECK') {
            return intl.formatMessage(messages.checkIcon);
        }
        throw new Error('No such icon state type');
    }

    addButtonDisabled(): boolean {
        return this.newSymbolType === 'USER_ICON' && !this.state.newFileIcon;
    }

    clearData() {
        this.setState({
            newHeader: '',
            newSymbol: '',
            newDescription: '',
        });
    }

    recoursiveClean(data: any): boolean {
        let result: boolean = false;
        for (const elem of data) {
            if (elem.className === TreeItemType.Folder && (!elem.children || elem.children.length === 0)) {
                data.splice(data.indexOf(elem), 1);
                result = result || true;
            } else {
                result = result || this.recoursiveClean(elem.children!);
            }
        }

        return result;
    }

    addNewHeader(rowColType: MatrixHeaderEnum, headerType: MatrixHeaderTypeEnum) {
        if (headerType === 'NODE' && (!this.props.selectedObjectId || this.props.selectedObjectId === '')) {
            return;
        }

        if (headerType === 'SIMPLE' && (!this.state.newHeader || this.state.newHeader === '')) {
            return;
        }

        const { content } = this.state;
        const matrix: IMatrixNode = content as IMatrixNode;
        if (headerType === 'SIMPLE') {
            matrix.data = matrixService().addMatrixHeader(matrix.data!, rowColType, headerType, this.state.newHeader);
            this.setState({ newHeader: '' });
        } else if (headerType === 'NODE') {
            matrix.data = matrixService().addMatrixHeader(
                matrix.data!,
                rowColType,
                headerType,
                this.props.selectedObjectId,
            );
        } else {
            throw Error('No such header type implemetation');
        }

        this.setState({ content: matrix });
    }

    deleteHeader(type: MatrixHeaderEnum, orderNum: number) {
        const { content } = this.state;
        const matrix: IMatrixNode = content as IMatrixNode;
        const updMatrix: IMatrixNode = matrixService().deleteMatrixHeader(matrix, type, orderNum);
        this.setState({ content: updMatrix });
    }

    pushHeader(type: MatrixHeaderEnum, orderNumber: number, direction: PushDirectionEnum) {
        const { content } = this.state;
        const matrix: IMatrixNode = content as IMatrixNode;
        const updMatrix: IMatrixNode = matrixService().pushHeader(matrix, type, orderNumber, direction);
        this.setState({ content: updMatrix });
    }

    deleteState(stateId: string) {
        const { content } = this.state;
        const matrix: IMatrixNode = content as IMatrixNode;
        const updMatrix: IMatrixNode = matrixService().deleteState(matrix, stateId);
        this.setState({ content: updMatrix });
    }

    pushState(stateId: string, direction: PushDirectionEnum) {
        const { content } = this.state;
        const matrix: IMatrixNode = content as IMatrixNode;
        const updMatrix: IMatrixNode = matrixService().pushState(matrix, stateId, direction);
        this.setContent(updMatrix.data!);
    }

    addNewMatrixState() {
        const { onFileDrop, serverId } = this.props;
        const { content } = this.state;
        const matrix: IMatrixNode = content as IMatrixNode;
        let data: MatrixData;

        if (this.newSymbolType === 'CUSTOM') {
            if (this.state.newSymbol) {
                data = matrixService().addMatrixCustomState(
                    matrix.data!,
                    this.state.newSymbol,
                    this.state.newColor,
                    this.state.newDescription,
                );

                this.setContent(data);
            }
        } else if (this.newSymbolType === 'ICON') {
            data = matrixService().addMatrixIconState(
                matrix.data!,
                this.newSymbolIconType,
                this.state.newColor,
                this.state.newDescription,
            );

            this.setContent(data);
        } else if (this.newSymbolType === 'USER_ICON') {
            onFileDrop(
                matrix.nodeId,
                matrix.data!,
                this.state.newFileIcon!,
                serverId,
                this.state.newDescription,
                this.setContent,
            );
            this.setState({ newFileIcon: undefined });
        } else {
            throw new Error('No such type matrix state');
        }
        this.clearData();
    }

    renderHeader(header: MatrixHeader, treeItemsById: { [id: string]: TreeNode }): ReactNode {
        if (header.type === 'SIMPLE') {
            const simpleHeader: SimpleMatrixHeader = header as SimpleMatrixHeader;

            return <span>{simpleHeader.title}</span>;
        }
        if (header.type === 'NODE') {
            const nodeHeader: NodeMatrixHeader = header as NodeMatrixHeader;
            const node: TreeNode = treeItemsById[nodeHeader.nodeId];
            const title: string = node && node.name;

            return <span>{title}</span>;
        }
        throw new Error('No such type header implemented');
    }

    renderSymbolColumn(record: MatrixState) {
        const { serverUrl } = this.props;

        if (record.type === 'CUSTOM') {
            const customState: CustomMatrixState = record as CustomMatrixState;

            return (
                <div>
                    <span style={{ color: customState.color }}>{customState.text}</span>
                </div>
            );
        }
        if (record.type === 'ICON') {
            const iconState: IconMatrixState = record as IconMatrixState;
            if (iconState.icon === 'PLUS') {
                return (
                    <div>
                        <PlusOutlined style={{ color: iconState.color }} />
                    </div>
                );
            }
            if (iconState.icon === 'MINUS') {
                return (
                    <div>
                        <MinusOutlined style={{ color: iconState.color }} />
                    </div>
                );
            }
            if (iconState.icon === 'CHECK') {
                return (
                    <div>
                        <CheckOutlined style={{ color: iconState.color }} />
                    </div>
                );
            }
            if (iconState.icon === 'CROSS') {
                return (
                    <div>
                        <CloseOutlined style={{ color: iconState.color }} />
                    </div>
                );
            }
            throw new Error('Such state icon type not implemented yet');
        } else if (record.type === 'USER_ICON') {
            const userIconState: UserIconMatrixState = record as UserIconMatrixState;

            return (
                <div>
                    <img
                        alt=""
                        src={`${serverUrl}/${PictureSymbolConstants.DOWNLOAD_LINK}/${userIconState.iconPath}/`}
                    />
                </div>
            );
        } else {
            throw new Error('Such matrix state type not implemented yet');
        }
    }

    renderButtonsColumn(stateId: string) {
        return (
            <div key={stateId} className={theme.matrixHeaderContainer}>
                <Button
                    className={theme.tableButton}
                    type="text"
                    onClick={() => {
                        this.onClickHandler(stateId);
                    }}
                >
                    <DeleteOutlined />
                </Button>
                <Button
                    className={theme.tableButton}
                    type="text"
                    onClick={() => {
                        this.onClickHandler(stateId, PushDirectionEnum.DOWN);
                    }}
                >
                    <DownOutlined />
                </Button>
                <Button
                    className={theme.tableButton}
                    type="text"
                    onClick={() => {
                        this.onClickHandler(stateId, PushDirectionEnum.UP);
                    }}
                >
                    <UpOutlined />
                </Button>
            </div>
        );
    }

    render() {
        const { open, intl, treeItemsById, activeTab, treeNodes, onSubmit } = this.props;
        const { content, newHeader, newSymbol, newColor, newDescription, newFileIcon } = this.state;

        const dialogProps: Partial<TDialogProps> = {
            open,
            className: theme.dialog,
            title: <div className={theme.title}>{intl.formatMessage(messages.title)}</div>,
            onCancel: () => {
                onSubmit(this.backupMatrix.nodeId, this.backupMatrix.data, this.backupMatrix.data2);
            },
            closable: true,
            width: 768,
            maskClosable: true,
        };

        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            onSubmit(this.backupMatrix.nodeId, this.backupMatrix.data, this.backupMatrix.data2);
                        },
                        value: intl.formatMessage(messages.cancelButton),
                    },
                    {
                        key: 'ok',
                        onClick: () => {
                            onSubmit(content.nodeId, content.data, content.data2);
                        },
                        value: intl.formatMessage(messages.okButton),
                        visualStyle: 'primary',
                    },
                ]}
            />
        );

        this.columns = [
            {
                width: '20%',
                title: intl.formatMessage(messages.iconSymbol),
                dataIndex: 'id',
                render: (value: string, record: MatrixState) => {
                    return this.renderSymbolColumn(record);
                },
            },
            {
                width: '60%',
                title: intl.formatMessage(messages.iconDescription),
                dataIndex: 'description',
            },
            {
                width: '20%',
                title: intl.formatMessage(messages.actions),
                dataIndex: 'orderNumber',
                render: (value: string, record: MatrixState) => {
                    return this.renderButtonsColumn(record.id);
                },
            },
        ];

        const handleChangeColorComplete = (color: ColorResult) => {
            this.setState({ newColor: color.hex });
        };
        const handleStateTypeMenuClick = (e: MenuInfo) => {
            this.newSymbolType = e.key as MatrixStateTypeEnum;
            this.forceUpdate();
        };
        const handleStateIconMenuClick = (e: MenuInfo) => {
            this.newSymbolIconType = e.key as IconMatrixStateIconEnum;
            this.forceUpdate();
        };

        const stateTypeMenu = (
            <Menu onClick={handleStateTypeMenuClick} className={theme.stateTypeMenu}>
                <Menu.Item key="CUSTOM">{intl.formatMessage(messages.customState)}</Menu.Item>
                <Menu.Item key="ICON">{intl.formatMessage(messages.iconState)}</Menu.Item>
                <Menu.Item key="USER_ICON">{intl.formatMessage(messages.userIconState)}</Menu.Item>
            </Menu>
        );

        const stateIconMenu = (
            <Menu onClick={handleStateIconMenuClick} className={theme.stateIconMenu}>
                <Menu.Item key="PLUS">{intl.formatMessage(messages.plusIcon)}</Menu.Item>
                <Menu.Item key="MINUS">{intl.formatMessage(messages.minusIcon)}</Menu.Item>
                <Menu.Item key="CROSS">{intl.formatMessage(messages.crossIcon)}</Menu.Item>
                <Menu.Item key="CHECK">{intl.formatMessage(messages.checkIcon)}</Menu.Item>
            </Menu>
        );
        const newHeaderNode: TreeNode =
            this.props.selectedObjectId && treeItemsById
                ? treeItemsById[this.props.selectedObjectId]
                : ({} as TreeNode);
        const newHeaderText = newHeaderNode && newHeaderNode.name ? newHeaderNode.name.slice(0, 30) : '';

        const axisXHeadersChilds: React.ReactNode[] = this.getHeadersChilds(MatrixHeaderEnum.ColumnHeader);
        const axisYHeadersChilds: React.ReactNode[] = this.getHeadersChilds(MatrixHeaderEnum.RowHeader);

        const tabs = [
            {
                label: intl.formatMessage(messages.settingsAxisX),
                key: '1',
                children: (
                    <SettingsAxis
                        treeNodes={treeNodes}
                        newHeaderNodeId={this.props.selectedObjectId}
                        newHeaderText={newHeaderText}
                        newHeader={newHeader}
                        headersChilds={axisXHeadersChilds}
                        addNewHeader={(headerType: MatrixHeaderTypeEnum) => {
                            this.addNewHeader(MatrixHeaderEnum.ColumnHeader, headerType);
                        }}
                        setNewHeader={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                            this.setNewHeader(value);
                        }}
                    />
                ),
            },
            {
                label: intl.formatMessage(messages.settingsAxisY),
                key: '2',
                children: (
                    <SettingsAxis
                        treeNodes={treeNodes}
                        newHeaderNodeId={this.props.selectedObjectId}
                        newHeaderText={newHeaderText}
                        newHeader={newHeader}
                        headersChilds={axisYHeadersChilds}
                        addNewHeader={(headerType: MatrixHeaderTypeEnum) => {
                            this.addNewHeader(MatrixHeaderEnum.RowHeader, headerType);
                        }}
                        setNewHeader={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                            this.setNewHeader(value);
                        }}
                    />
                ),
            },
            // TODO: вынести в отдельный компонент
            {
                label: intl.formatMessage(messages.settingsLinks),
                key: '3',
                children: (
                    <FormGroup className={theme.formGroup}>
                        <Form.Item>
                            <div className={theme.formItemContent}>
                                <Table
                                    rowKey={(record: MatrixState) => `${record.id}`}
                                    columns={this.columns}
                                    dataSource={content.data!.states}
                                    size="small"
                                    className={theme.table}
                                    bordered
                                    pagination={false}
                                />
                                <div>
                                    <Dropdown
                                        className={theme.stateTypeMenu}
                                        overlay={stateTypeMenu}
                                        onOpenChange={() => {
                                            this.clearData();
                                        }}
                                        destroyPopupOnHide
                                    >
                                        <Button style={{ margin: '10px 0' }}>{this.getStateTypeTitle()}</Button>
                                    </Dropdown>
                                    <Row
                                        style={{
                                            display: this.newSymbolType === 'CUSTOM' ? 'flex' : 'none',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div className={theme.stateWrapper}>
                                            <Form.Item
                                                noStyle
                                                style={{ marginRight: 5 }}
                                                validateStatus={
                                                    newSymbol === undefined || newSymbol.length > 0
                                                        ? 'success'
                                                        : 'error'
                                                }
                                            >
                                                <Input
                                                    className={theme.randomSymbolInput}
                                                    placeholder={intl.formatMessage(messages.iconSymbol)}
                                                    defaultValue=""
                                                    value={newSymbol}
                                                    onChange={({
                                                        target: { value },
                                                    }: ChangeEvent<HTMLInputElement>) => {
                                                        this.setNewSymbol(value);
                                                    }}
                                                    onBlur={() => {
                                                        if (newSymbol === undefined) this.setNewSymbol('');
                                                    }}
                                                    maxLength={1}
                                                />
                                            </Form.Item>

                                            <Popover
                                                content={
                                                    <SketchPicker
                                                        color={newColor}
                                                        onChangeComplete={handleChangeColorComplete}
                                                    />
                                                }
                                            >
                                                <Button className={theme.buttonTextColor}>
                                                    <Icon spriteSymbol={icTextColor} />
                                                </Button>
                                            </Popover>
                                        </div>
                                        <div>
                                            <Form.Item validateStatus="success">
                                                <Input
                                                    placeholder={intl.formatMessage(messages.iconDescription)}
                                                    defaultValue=""
                                                    value={newDescription}
                                                    onChange={({
                                                        target: { value },
                                                    }: ChangeEvent<HTMLInputElement>) => {
                                                        this.setNewDescription(value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </Row>

                                    <Row style={{ display: this.newSymbolType === 'ICON' ? 'flex' : 'none' }}>
                                        <Col>
                                            <Dropdown
                                                destroyPopupOnHide
                                                overlay={stateIconMenu}
                                                className={theme.stateIconMenu}
                                            >
                                                <Button>{this.getStateIconTitle()}</Button>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Popover
                                                content={
                                                    <SketchPicker
                                                        color={newColor}
                                                        onChangeComplete={handleChangeColorComplete}
                                                    />
                                                }
                                            >
                                                <Button className={theme.buttonTextColor}>
                                                    <Icon spriteSymbol={icTextColor} />
                                                </Button>
                                            </Popover>
                                        </Col>
                                        <Col>
                                            <Form.Item validateStatus="success">
                                                <Input
                                                    placeholder={intl.formatMessage(messages.iconDescription)}
                                                    defaultValue=""
                                                    value={newDescription}
                                                    onChange={({
                                                        target: { value },
                                                    }: ChangeEvent<HTMLInputElement>) => {
                                                        this.setNewDescription(value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            display: this.newSymbolType === 'USER_ICON' ? 'flex' : 'none',
                                        }}
                                    >
                                        <Col span={15}>
                                            {intl.formatMessage(messages.fileDropText)}
                                            <Upload.Dragger
                                                className={theme.fileDragger}
                                                multiple
                                                showUploadList={false}
                                                customRequest={(e) => this.onDrop(e.file as File)}
                                                accept="image/*"
                                            >
                                                {newFileIcon ? (
                                                    <Icon className={theme.uploadImg} spriteSymbol={iconOk} />
                                                ) : (
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                )}
                                            </Upload.Dragger>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item validateStatus="success">
                                                <Input
                                                    placeholder={intl.formatMessage(messages.iconDescription)}
                                                    defaultValue=""
                                                    value={newDescription}
                                                    onChange={({
                                                        target: { value },
                                                    }: ChangeEvent<HTMLInputElement>) => {
                                                        this.setNewDescription(value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <div>
                                        <Button
                                            style={{ margin: '10px 0' }}
                                            key="ok"
                                            size="large"
                                            type="primary"
                                            disabled={this.addButtonDisabled()}
                                            onClick={() => {
                                                this.addNewMatrixState();
                                            }}
                                        >
                                            {intl.formatMessage(messages.addButton)}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form.Item>
                    </FormGroup>
                ),
            },
        ];

        return (
            <div>
                <Dialog {...dialogProps} footer={footer}>
                    <Tabs
                        tabPosition="left"
                        className={theme.tabs}
                        defaultActiveKey={activeTab || '1'}
                        onChange={() => {
                            this.clearData();
                        }}
                        items={tabs}
                    />
                </Dialog>
            </div>
        );
    }
}

const IntlComponent = injectIntl(MatrixSettingsDialog);

export { IntlComponent as MatrixSettingsDialog };
