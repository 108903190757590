import React, { FC, useState } from 'react';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { Tabs } from 'antd';
import messages from './NewMatrixSettingsDialog.messages';
import theme from './NewMatrixSettingsDialog.scss';
import { useIntl } from 'react-intl';
import { EdgeStyleSettings } from './EdgeStyleSettings/EdgeStyleSettings.component';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { MatrixNode, MatrixStyle, NodeId } from '@/serverapi/api';
import { TDialogProps } from '@/modules/UIKit/components/Dialog/Dialog.types';
import { MatrixSelectors } from '@/selectors/entities/matrix.selectors';
import { changeMatrixProperties, matrixSaveRequest } from '@/actions/entities/matrix.actions';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TNewMatrixSettingsDialogProps = {
    nodeId: NodeId;
} & TDialogProps;

export const NewMatrixSettingsDialog: FC<TNewMatrixSettingsDialogProps> = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const intl = useIntl();
    const dispatch = useDispatch();
    const matrix: MatrixNode | undefined = useSelector(MatrixSelectors.byId(props.nodeId));
    const initStyles: MatrixStyle[] = [...(matrix?.data2?.cellSettings.styles || [])];
    const [currentStyles, setCurrentStyles] = useState<MatrixStyle[]>(initStyles);
    const [isAutomaticCellFillingMode, setIsAutomaticCellFillingMode] = useState<boolean | undefined>(
        matrix?.data2?.cellSettings.isAutomatic,
    );

    const tabs = [
        {
            label: intl.formatMessage(messages.settingsLinks),
            key: '1',
            children: (
                <EdgeStyleSettings
                    currentStyles={currentStyles}
                    isAutomaticCellFillingMode={isAutomaticCellFillingMode}
                    setCurrentStyles={setCurrentStyles}
                    setIsAutomaticCellFillingMode={setIsAutomaticCellFillingMode}
                />
            ),
        },
    ];

    const cancelHandler = () => {
        dispatch(closeDialog(DialogType.NEW_MATRIX_SETTINGS_DIALOG));
    };

    const submitHandler = () => {
        if (matrix?.data2?.cellSettings) {
            const newMatrix: MatrixNode = {
                ...matrix,
                data2: {
                    ...matrix.data2,
                    cellSettings: {
                        ...matrix.data2?.cellSettings,
                        isAutomatic: !!isAutomaticCellFillingMode,
                        styles: currentStyles,
                    },
                },
            };
            dispatch(changeMatrixProperties(props.nodeId, newMatrix));
            dispatch(matrixSaveRequest(newMatrix));
        }
        cancelHandler();
    };
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: cancelHandler,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: submitHandler,
                    value: intl.formatMessage(messages.okButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div>
            <Dialog
                {...props}
                title={intl.formatMessage(messages.title)}
                footer={footer}
                width={768}
                onCancel={cancelHandler}
            >
                <Tabs
                    tabPosition="left"
                    className={theme.tabs}
                    defaultActiveKey={activeTab || '1'}
                    onChange={(key) => setActiveTab(key)}
                    items={tabs}
                />
            </Dialog>
        </div>
    );
};
