import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './NewMatrixCreateDefinitionDialog.messages';
import { EdgeDefinitionDirection, TCreateDefinitionDialogProps } from './NewMatrixCreateDefinitionDialog.types';
import theme from './NewMatrixCreateDefinitionDialog.scss';
import { EdgeType } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { EdgeTypeSelectors } from '@/selectors/edgeType.selectors';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { SymbolToImageConverterGraph } from '@/modules/AdminTools/Methodology/components/Presets/SymbolToImageConverterGraph.component';
import { MethodologiesGraph } from '@/mxgraph/MethodologiesGraph';
import { Icon } from '@/modules/UIKit';
import defaultIcon from '@/resources/icons/defaultIcon.svg';
import swapDirectionIcon from '@/resources/icons/swap-vertical.svg';
import { createEdgeDefinitionFromMatrix } from '@/actions/entities/edgeDefinition.actions';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export const MatrixCreateDefinitionDialog: FC<TCreateDefinitionDialogProps> = (props) => {
    const { open, nodeId, currentCol, currentRow, colIcon, rowIcon } = props;

    const presetId = useSelector(TreeSelectors.presetById(nodeId));
    const edgeTypes: EdgeType[] = useSelector(EdgeTypeSelectors.listByPresetId(nodeId.serverId, presetId));

    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedEdgeTypeId, setSelectedEdgeTypeId] = useState<string>('');
    const [direction, setDirection] = useState<EdgeDefinitionDirection>(EdgeDefinitionDirection.direct);

    const currentLocale = useSelector(getCurrentLocale);

    const selectedEdgeType: EdgeType | undefined = edgeTypes.find((e) => e.id === selectedEdgeTypeId);

    const filteredEdgeTypes = edgeTypes
        .filter((edgeType) =>
            LocalesService.internationalStringToString(edgeType.multilingualName, currentLocale)
                .toLowerCase()
                .includes(searchValue.toLowerCase()),
        )
        .sort((a, b) => a.name.localeCompare(b.name));

    const dispatch = useDispatch();
    const intl = useIntl();

    const sumbitHandler = () => {
        if (selectedEdgeType) {
            const sourceObjectDefinitionId =
                direction === EdgeDefinitionDirection.direct ? currentRow.linkedNodeId : currentCol.linkedNodeId;
            const targetObjectDefinitionId =
                direction === EdgeDefinitionDirection.direct ? currentCol.linkedNodeId : currentRow.linkedNodeId;

            if (!sourceObjectDefinitionId || !targetObjectDefinitionId) return;

            dispatch(
                createEdgeDefinitionFromMatrix({
                    nodeId,
                    edgeType: selectedEdgeType,
                    sourceObjectDefinitionId,
                    targetObjectDefinitionId,
                }),
            );
        }
        cancelHandler();
    };

    const cancelHandler = () => {
        dispatch(closeDialog(DialogType.NEW_MATRIX_CREATE_DEFINITION_DIALOG));
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: cancelHandler,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: sumbitHandler,
                    value: intl.formatMessage(messages.submit),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    const renderSelectEdgeTypeValue = () => {
        const edgeType = filteredEdgeTypes.find((e) => e.id === selectedEdgeTypeId);

        if (!edgeType) return '';

        return (
            <div className={theme.selectEdge}>
                <div className={theme.selectEdgeText} data-test={`edge-type-select-option-${edgeType.name}`}>
                    <span>{edgeType.name}</span>
                </div>
                <div className={theme.selectEdgeImg}>
                    {SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph)}
                </div>
            </div>
        );
    };

    const converterInitialized = (methodologiesGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(methodologiesGraph);
        }
    };

    const colName = LocalesService.internationalStringToString(currentCol.text, currentLocale);
    const rowName = LocalesService.internationalStringToString(currentRow.text, currentLocale);

    const renderObject = (text: string, icon?: string) => {
        const renderedIcon = icon ? (
            <svg className={theme.icon}>
                <image xlinkHref={icon} />
            </svg>
        ) : (
            <Icon className={theme.icon} spriteSymbol={defaultIcon} />
        );

        return (
            <div className={theme.objectContainer}>
                <div className={theme.iconContainer}>{renderedIcon}</div>
                <div className={theme.text}>{text}</div>
            </div>
        );
    };

    const swapDirectionHandler = () => {
        if (direction === EdgeDefinitionDirection.direct) {
            setDirection(EdgeDefinitionDirection.reverse);
        } else {
            setDirection(EdgeDefinitionDirection.direct);
        }
    };

    return (
        <>
            <Dialog
                title={intl.formatMessage(messages.title)}
                footer={footer}
                onCancel={cancelHandler}
                open={open}
                width="480px"
            >
                <div className={theme.selectContainer}>
                    <Select
                        label={intl.formatMessage(messages.edgeType)}
                        value={renderSelectEdgeTypeValue()}
                        wrapperClassName={theme.svgSelect}
                        dropdownClassName={theme.svgSelectDropdown}
                        originalTheme
                        showSearch
                        searchValue={searchValue}
                        onSearch={(e) => {
                            setSearchValue(e);
                        }}
                        onChange={(val) => val && setSelectedEdgeTypeId(val)}
                    >
                        {filteredEdgeTypes.map((edgeType) => {
                            return (
                                <Select.Option
                                    key={edgeType.id}
                                    value={edgeType.id}
                                    label={
                                        <div className={theme.selectOption}>
                                            {edgeType.name}
                                            {SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph)}
                                        </div>
                                    }
                                />
                            );
                        })}
                    </Select>
                </div>
                <SymbolToImageConverterGraph initialized={converterInitialized} />
                <div className={theme.row}>
                    <div className={theme.col}>
                        <div className={theme.container}>
                            <div className={theme.title}>{intl.formatMessage(messages.source)}</div>
                            {direction === EdgeDefinitionDirection.direct
                                ? renderObject(rowName, rowIcon)
                                : renderObject(colName, colIcon)}
                        </div>
                        <div className={theme.container}>
                            <div className={theme.title}>{intl.formatMessage(messages.target)}</div>
                            {direction === EdgeDefinitionDirection.direct
                                ? renderObject(colName, colIcon)
                                : renderObject(rowName, rowIcon)}
                        </div>
                    </div>
                    <Icon className={theme.swapIcon} spriteSymbol={swapDirectionIcon} onClick={swapDirectionHandler} />
                </div>
            </Dialog>
        </>
    );
};
