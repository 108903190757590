import { Checkbox, DatePicker, Form, Input, Row, Select, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FileUploadStatus, ParameterType } from '../../../../reducers/scriptExecuteDialog.reducer.types';
import { AttributeValueQuery, ScriptParameter, TableScriptParameterGroup } from '../../../../serverapi/api';
import messages from './ScriptExecuteDialog.messages';
import theme from './ScriptExecuteDialog.scss';
import { Option } from 'rc-select';
import editIcon from '@/resources/icons/edit.svg';
import deleteIcon from '@/resources/icons/Deleted.svg';
import { TScriptExecuteFormAllProps } from './ScriptExecuteDialog.types';
import { QuerySelect } from '../../../UIKit/components/QuerySelect/QuerySelect.component';
import { useDispatch, useSelector } from 'react-redux';
import { loadScriptQueryParamValues } from '../../../../actions/scriptQueryParams.actions';
import { ScriptQueryParamsSelector } from '../../../../selectors/scriptQueryParams.selectors';
import { TScriptQueryParamsState } from '../../../../reducers/scriptQueryParams.reducer.types';
import { TOOLTIP_ENTER_DELAY, TOOLTIP_LEAVE_DELAY } from '@/utils/configuration';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import icApprovalStageProgress from '@/resources/icons/ic-approval-stage-progress.svg';
import { Icon } from '@/modules/UIKit';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';

export const ScriptExecuteForm = (props: TScriptExecuteFormAllProps) => {
    const {
        parsedParams,
        scriptId,
        onUploadFile,
        onAddNode,
        fileParams,
        nodeParams,
        onDeleteNode,
        form,
        existingParams,
        parameterGroups
    } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const queryValues: TScriptQueryParamsState = useSelector(
        ScriptQueryParamsSelector.getScriptQueryParams(parsedParams),
    );

    useEffect(() => {
        const modelIdString: string | undefined = existingParams.find((param) => param.name === 'modelId')?.value;

        dispatch(loadScriptQueryParamValues(parsedParams, modelIdString));
    }, []);

    const handleUploadFile = (paramName: string) => () => {
        onUploadFile(scriptId.serverId, paramName);
    };

    const handleAddNode = (paramName: string) => {
        onAddNode(paramName);
    };

    const handleDeleteNode = (paramName: string) => {
        onDeleteNode(paramName);
        form.validateFields([paramName]);
    };

    const getParamLabel = (parameter: ScriptParameter): string => {
        const currentLocale = useSelector(getCurrentLocale);

        return (parameter.label && LocalesService.internationalStringToString(parameter.label, currentLocale)) || parameter.name || '';
    };

    const getStringField = (parameter: ScriptParameter) => {
        const defaultValue = parameter.value || parameter.defaultValue || '';

        return (
            <Form.Item
                initialValue={defaultValue}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
                name={parameter.name || ''}
                key={parameter.name}
            >
                <Input type="text" />
            </Form.Item>
        );
    };

    const getOutStringField = (parameter: ScriptParameter) => {
        const defaultValue = parameter.value || parameter.defaultValue || '';

        return (
            <Form.Item
                name={parameter.name || ''}
                key={parameter.name}
            >
                {defaultValue}
            </Form.Item>
        );
    };

    const getOutNodeField = (parameter: ScriptParameter) => {
        const defaultValue = parameter.value || parameter.defaultValue || '';

        return (
            <Form.Item
                name={parameter.name || ''}
                key={parameter.name}
            >
                {defaultValue}
            </Form.Item>
        );
    };

    const getNumberField = (parameter: ScriptParameter) => {
        return (
            <Form.Item
                name={parameter.name || ''}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
                initialValue={parameter.value || parameter.defaultValue}
                className={theme.field}
                key={parameter.name}
                getValueFromEvent={(e) => e.target.value.replace(/[^0-9]/, '')}
            >
                <Input className={theme.number} />
            </Form.Item>
        );
    };

    const getBooleanField = (parameter: ScriptParameter) => {
        const defaultValue: boolean =
            parameter.value !== undefined && parameter.value !== ''
                ? parameter.value.toLowerCase() === 'true'
                : parameter.defaultValue?.toLowerCase() === 'true';

        return (
            <Form.Item
                required={parameter.required}
                initialValue={defaultValue}
                valuePropName="checked"
                name={parameter.name || ''}
                key={parameter.name}
            >
                <Checkbox className={theme.checkbox} />
            </Form.Item>
        );
    };

    const getFileField = (parameter: ScriptParameter) => {
        const paramName = parameter.name!;
        const { required } = parameter;
        const fileParam = fileParams?.[paramName];
        const loading: boolean = fileParam?.uploadStatus === FileUploadStatus.LOADING;
        const loaded: boolean = fileParam?.uploadStatus === FileUploadStatus.DONE;
        const needsLoading: boolean = !fileParams[paramName];
        if (!needsLoading) form.validateFields([paramName]);

        return (
            <Form.Item
                key={paramName}
                name={paramName}
                className={theme.fileField}
                rules={[
                    {
                        validator: (_) => {
                            return !needsLoading || !required ? Promise.resolve() : Promise.reject();
                        },
                        message: intl.formatMessage(messages.missingParamFileMessage),
                    },
                ]}
            >
                <Button key={paramName} onClick={handleUploadFile(paramName)} disabled={loading}>
                    {intl.formatMessage(messages.loadFileButton)}
                </Button>
                {loading ? (
                    <span className={theme.rotatingIconWrapper}>
                        <Icon className={theme.rotatingIcon} spriteSymbol={icApprovalStageProgress} />
                    </span>
                ) : (
                    ''
                )}
                {(loaded && !loading) && (
                    <span className={theme.loadedFileText}>
                        {intl.formatMessage(messages.loadedFileName)}: {fileParam.originalFileName}
                    </span>
                )}
            </Form.Item>
        );
    };

    const getDateField = (parameter: ScriptParameter) => {
        const { name = '', defaultValue, value, required } = parameter;
        let initialValue: dayjs.Dayjs = dayjs();

        if (value !== undefined) {
            try {
                initialValue = dayjs.unix(+value);
            } catch (e) {
                // обработчик на всякий случай
            }
        } else if (defaultValue !== undefined) {
            try {
                initialValue = dayjs.unix(+defaultValue);
            } catch (e) {
                // обработчик на всякий случай
            }
        }

        if (!initialValue.isValid()) {
            initialValue = dayjs();
        }

        return (
            <Form.Item
                required={required}
                name={name}
                initialValue={initialValue}
                key={name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <DatePicker
                    showTime
                    format="DD-MM-YYYY HH:mm:ss"
                    placeholder={intl.formatMessage(messages.selectDateMessage)}
                />
            </Form.Item>
        );
    };

    const getSelectStringField = (parameter: ScriptParameter) => {
        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <Select>
                    {parameter.selectStringValues?.map((value) => (
                        <Option key={value} value={value}>
                            {value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );
    };

    const getSelectObjectField = (parameter: ScriptParameter) => {
        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required: parameter.required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <Select>
                    {parameter.selectKeyValues?.map((value) => (
                        <Option key={value.key} value={value.key!}>
                            {value.label?.ru}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );
    };

    const getNodeField = (parameter: ScriptParameter) => {
        const { name = '', required } = parameter;
        const isSelected: boolean = !!nodeParams[name]?.path;
        if (isSelected) form.validateFields([name]);

        return (
            <Form.Item
                name={name}
                key={name}
                rules={[
                    {
                        validator: (_) => {
                            return isSelected || !required ? Promise.resolve() : Promise.reject();
                        },
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <Row justify="space-between" className={theme.row}>
                    <Input className={theme.node} disabled type="text" value={nodeParams[name]?.path} />

                    <Button icon={editIcon} onClick={() => handleAddNode(name)} />
                    {nodeParams[name]?.path && <Button icon={deleteIcon} onClick={() => handleDeleteNode(name)} />}
                </Row>
            </Form.Item>
        );
    };

    const getQueryField = (parameter: ScriptParameter) => {
        const { name = '', required } = parameter;

        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <QuerySelect data={queryValues[name] || []} onChange={() => undefined} />
            </Form.Item>
        );
    };

    const getQueryMultiField = (parameter: ScriptParameter) => {
        const { name = '', required } = parameter;

        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <QuerySelect data={queryValues[name] || []} onChange={() => undefined} isMultiSelect />
            </Form.Item>
        );
    };

    const getMultiSelectObjectField = (parameter: ScriptParameter) => {
        const { required } = parameter;

        return (
            <Form.Item
                initialValue={parameter.value || parameter.defaultValue}
                name={parameter.name || ''}
                key={parameter.name}
                rules={[
                    {
                        required,
                        message: intl.formatMessage(messages.missingRequiredParameter),
                    },
                ]}
            >
                <QuerySelect data={getMultiSelectKeyValues(parameter)} onChange={() => undefined} isMultiSelect />
            </Form.Item>
        );
    };

    const getMultiSelectKeyValues = (param: ScriptParameter): AttributeValueQuery[] => {
        const currentLocale = useSelector(getCurrentLocale);

        return param.selectKeyValues?.map(v => ({ id: v.key, value: v.label && LocalesService.internationalStringToString(v.label, currentLocale) } as AttributeValueQuery)) || []
    }

    const getDescriptionTooltip = (param: ScriptParameter) => {
        return (
            <Tooltip
                title={param.description}
                mouseEnterDelay={TOOLTIP_ENTER_DELAY}
                mouseLeaveDelay={TOOLTIP_LEAVE_DELAY}
                placement="topLeft"
            >
                <div className={param.required ? theme.requidedParamName : undefined}>{getParamLabel(param)}</div>
            </Tooltip>
        );
    };

    const getColumns = (parameterGroup: TableScriptParameterGroup) => {
        return [
            {
                title: parameterGroup?.firstColumnHeader || intl.formatMessage(messages.scriptParam),
                dataIndex: 'name',
                key: 'name',
                width: 180,
            },
            {
                title: parameterGroup?.secondColumnHeader || intl.formatMessage(messages.scriptParamValue),
                dataIndex: 'paramValue',
                key: 'paramValue',
            },
        ];
    };

    const getDataSourse = (params: ScriptParameter[]) => {
        return params.filter(p => !p.hide).map((parameter) => {
            if (!parameter.paramType) {
                return {};
            }
            switch (parameter.paramType.toString().toUpperCase()) {
                case ParameterType.STRING:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getStringField(parameter),
                    };
                case ParameterType.NUMBER:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getNumberField(parameter),
                    };
                case ParameterType.BOOLEAN:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getBooleanField(parameter),
                    };
                case ParameterType.FILE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getFileField(parameter),
                    };
                case ParameterType.NODE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getNodeField(parameter),
                    };
                case ParameterType.DATE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getDateField(parameter),
                    };
                case ParameterType.SELECT_STRING:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getSelectStringField(parameter),
                    };
                case ParameterType.QUERY_SELECT:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getQueryField(parameter),
                    };
                case ParameterType.QUERY_MULTI_SELECT:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getQueryMultiField(parameter),
                    };
                case ParameterType.OUT_STRING:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getOutStringField(parameter),
                    };
                case ParameterType.OUT_NODE:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getOutNodeField(parameter),
                    };
                case ParameterType.KEY_VALUE_SELECT:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getSelectObjectField(parameter),
                    };
                case ParameterType.KEY_VALUE_MULTI_SELECT:
                    return {
                        name: getDescriptionTooltip(parameter),
                        paramValue: getMultiSelectObjectField(parameter),
                    };
                default:
                    return {};
            }
        });
    };

    const getParameterGroupsForm = (params: ScriptParameter[]) => {
        const result: JSX.Element[] = [];
        Map.groupBy(params, ({groupId}) => groupId)
            .forEach((groupParams, groupId) => {
                const dataSource = getDataSourse(groupParams || []);
                const group = parameterGroups?.find(g => g.id === groupId);

                if (dataSource.length > 0 && (!group || !groupId || group.type === 'TABLE')) {
                    const tableGroup = group as TableScriptParameterGroup;

                    result.push((
                        <Form.Item>
                            <Table
                                className={theme.table}
                                columns={getColumns(tableGroup)}
                                dataSource={dataSource}
                                pagination={false}
                                showHeader={!tableGroup?.hideColumnHeaders}
                            />
                        </Form.Item>
                    ));
                }
            });

        return result;
    };

    return (
        <Form form={form}>
            {(!!parsedParams?.length || !!props.message) && (
                <div className={theme.description}>{props.message || intl.formatMessage(messages.enterParametersValuesMessage)}</div>
            )}
            {(
                getParameterGroupsForm(parsedParams || [])
            )}
        </Form>
    );
};
